export const PATHS = {
  LANDING: '/',
  LOGIN: '/login',
  HOME: '/home',
  PATH_SIGNUP: '/signup',
  PATH_VERIFY_EMAIL: '/verify-email',
  PATH_GENERAL_INFO: '/general-info',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  PATH_WORK: '/work',
  PROFILE: '/my-profile',
  MYNETWORK: '/my-network',
  OTHER_USER_PROFILE: '/profile/',
  MY_NOTIFICATION: '/notification',
  SETTINGS: '/settings',
  SETTINGS_CHANGE_PASSWORD: '/settings/change-password',
  SETTINGS_HELP_CENTER: '/settings/help-center',
};
